.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 10%;
  box-sizing: border-box;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)), url('./12.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: white;
  flex-direction: column;
  /* Add this line */
}

.contactHeaderSection {
  text-align: center;
  /* Center the text */
  margin-bottom: 20px;
  /* Add some space below the header */
  color: white;
}

.contactHeaderSection h6 {
  font-size: 2rem;
  font-weight: 300;
}

.contactHeaderSection h2 {
  font-size: 4rem;
  line-height: 1em;
  font-weight: 500;
  margin-bottom: 5rem;
}


.form {
  width: 100%;
  max-width: 820px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}


form {
  padding: 2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.title {
  color: #fff;
  font-weight: 500;
  font-size: 4rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.input-container {
  position: relative;
  margin: 1rem 0;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fff;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  /* border-radius: 25px; */
  transition: 0.3s;
}

textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  /* border-radius: 22px; */
  resize: none;
  overflow-y: auto;
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;
}

.input-container.textarea label {
  top: 1rem;
  transform: translateY(0);
}

.input-container input:focus+label,
.input-container input:not(:placeholder-shown)+label,
.input-container textarea:focus+label,
.input-container textarea:not(:placeholder-shown)+label {
  top: -10px;
  left: 10px;
  font-size: 12px;
  color: #333;
}

.btn {
  padding: 0.6rem 1.3rem;
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  font-size: 0.95rem;
  color: white;
  line-height: 1;
  border-radius: 0px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
}

.btn:hover {
  background-color: #fff;
  color: var(--primary-color);
  /* border: 2px solid  var(--primary-color); */
}



.contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-info .title {
  color: var(--primary-color);
}

.text {
  color: #fff;
  margin: 1.5rem 0 2rem 0;
}


.status-icon{
  color: var(--primary-color);
}

@media (max-width: 850px) {
  .form {
    grid-template-columns: 1fr;
  }

  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .text {
    margin: 1rem 0 1.5rem 0;
    color: #fff;
  }


}

@media (max-width: 480px) {
  .contact-container {
    padding: 1.5rem;
    height: auto;
  }

  .contactHeaderSection h6 {
    margin-top: 5%;
    font-size: 1.2rem;
    font-weight: 300;
  }
  
  .contactHeaderSection h2 {
    font-size: 2rem;
    line-height: 1em;
    font-weight: 500;
  }

  .form{
    height: auto;
  }

  .contact-info:before {
    display: none;
  }

  form,
  .contact-info {
    padding: 1.7rem 1.6rem;
  }

  .text,
  .information {
    font-size: 1.2rem;
  }

  .title {
    font-size:2rem;
  }

  .input {
    padding: 0.45rem 1.2rem;
  }

  textarea.input {
    min-height: 120px;
   
  }

  .btn {
    padding: 0.45rem 1.2rem;
  }
}