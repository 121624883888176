.serviceBlock {
  color: white;
  padding-bottom: 10%;
}

.servicesHeaderWrapper {
  width: 100%;
  overflow: hidden; /* Prevent content from overflowing */
}


.servicesHeaderSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw; /* Full width of the viewport */
  height: 400px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(255, 255, 255, 0)), url('./8.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; /* Ensure the image covers the entire section */
  color: white;
  margin-left: calc(-50vw + 50%); /* Center the section */
  margin-right: calc(-50vw + 50%); /* Center the section */
}


.servicesHeaderSection h6 {
  font-size: 2rem;
  font-weight: 300;
}

.servicesHeaderSection h1 {
  font-size: 4.5rem;
  line-height: 1em;
  font-weight: 500;
}

.additionalServicesContent {
  margin-top: 5%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}

.card {
  color: #000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  height: 300px;
  padding: 3em;
  background: #f3f6fc;
  border: 1px solid #fff;
  border-radius: 0px;
  box-shadow: 0 15px 60px rgba(0, 0, 0, 0.2);
  margin-bottom: 10%;
  transition: all 0.5s ease;
}

.face {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.face.back {
  box-sizing: border-box;
  padding: 20px;
}

.face.back p {
  margin: 0;
  padding: 0;
  color: #000;
  text-align: center;
  font-size: 1.5;
}

.face.front {
  transition: 0.5s;
  background-color: #fff;
  border-radius: 15px;
}

.face.front h6 {
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  color: #000;
  transition: 0.5s;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.icon-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s ease;
}

.icon-title .icon {
  font-size: 2rem;
  margin-bottom: 10px;
}

.card:hover .icon-title {
  flex-direction: row;
}

.card:hover .icon-title .icon {
  margin-right: 10px;
  margin-bottom: 0;
}

.card:hover .face.front {
  height: 60px;
  opacity: 1;
}

.card:hover .face.front h2 {
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .servicesHeaderSection {
    height: auto;
    padding: 20px;
  }

  .servicesHeaderSection h6 {
    font-size: 1.5rem;
  }

  .servicesHeaderSection h1 {
    font-size: 2.5rem;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .servicesHeaderSection {
    height: auto;
    padding: 50% 0 50% 0;
  }

  .servicesHeaderSection h6 {
    font-size: 1.2rem;
  }

  .servicesHeaderSection h1 {
    font-size: 2rem;
    text-align: center;
  }

  .additionalServicesContent {
    flex-direction: column;
  }

  .serviceCard {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .card {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}