/* Navbar.css */
.nav {
  background: transparent;
  height: 80px;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: 0.8s all ease;
}

.nav-scroll {
  background: #000 !important;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
}

.nav-logo {
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none !important;
}

.mobile-icon {
  display: none;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff !important;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 1em 1em;
  height: 100%;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
}

.nav-links:hover {
  color: #f55422 !important;
}



@media screen and (max-width: 768px) {
  .mobile-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 2rem;
    cursor: pointer;
    color: #fff;
  }
  
  .nav{
    background: #000 !important;
    margin-bottom: 80px;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;
    width: 100%;
    height: 100vh;
    background: #000;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
  }
  
  .nav-menu.active {
    left: 0;
    opacity: 1;
  }
  
  
}