:root {
  /* --primary-color: #F05D23; */
  --primary-color: #f55422;
  --secondary-color: #007900;
  --accent-color: #F7F7F2;
  --text-color: #260C1A;
  --background-color: #FCFCFC;
}



@font-face {
  font-family: 'Le Jour Serif';
  src: url('../public/assets/fonts/Le Jour Serif.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
