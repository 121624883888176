.homeContainer {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(255, 255, 255, 0)), url('../../public/assets/images/3.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


.content {
  justify-content: flex-start;
  align-items: center;
  color: #fff;
} 

.content h6 {
  font-size: 2rem;
  font-weight: 300;
}
.content h1 {
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 4.5rem;
  line-height: 1.5em;
  font-weight: 700;
}

.homeButton {
  font-size: 1.3rem;
  padding: 1em;
  background-color: transparent;
  color: var(--background-color);
  border: 3px solid var(--background-color);
  /* border-radius: 20%; */
  border-color: var(--background-color);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.homeButton:hover, .homeButton:active, .homeButton:visited {
  background-color: var(--primary-color) !important;;
  border-color: var(--primary-color) !important;;
  transform: scale(1.01); 
  color: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,0.25);
}


/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .homeContainer {
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
  }

  .content h6 {
    font-size: 1.5rem;
  }

  .content h1 {
    font-size: 2.5rem;
    text-align: center;
  }

  .homeButton {
    font-size: 1rem;
    padding: 0.8em;
  }
}

@media (max-width: 480px) {
  .homeContainer {
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 10px;
  }

  .content {
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  .content h6 {
    font-size: 1.2rem;
    text-align: center;
  }

  .content h1 {
    font-size: 2rem;
    text-align: center;
  }

  .homeButton {
    font-size: 1.2rem;
    padding: 0.6em;
  
  display: flex;
  justify-content: center;
  align-items: center;
    margin: 0 auto; /* Center the button */
  }
}

