.aboutContainer {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(255, 255, 255, 0)),
    url('./9.jpg');
  background-repeat: no-repeat;
  background-position: center center;

  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}




.aboutContent {
  justify-content: flex-start;
  align-items: center;

  color: #fff;
  width: 70%;
}

.aboutContentTitle {
  font-size: 2rem;
  font-weight: 300;
}

.aboutContentSubTitle {
  font-size: 4rem;
  line-height: 1em;
  font-weight: 500;
  margin-bottom: 5rem;
}

.aboutContentDescription {
  font-size: 3rem;
  line-height: 1em;
  font-weight: 200;
  margin-bottom: 5rem;
}


.founder-details h3 {
  font-size: 2rem;
  font-weight: 300;
}

.founder-details p {
  font-size: 2rem;
  font-weight: 300;
}


/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .aboutContent {
    width: 100%;
  }

  .aboutContentTitle {
    font-size: 1.5rem;
  }

  .aboutContentSubTitle {
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  .aboutContentDescription {
    font-size: 2rem;
    margin-bottom: 3rem;
  }

  .founder-details h3 {
    font-size: 1.5rem;
  }

  .founder-details p {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .aboutContent {
    width: 100%;
    padding: 20px;
  }

  .aboutContentTitle {
    font-size: 1.2rem;
  }

  .aboutContentSubTitle {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .aboutContentDescription {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  .founder-details h3 {
    font-size: 1.2rem;
  }

  .founder-details p {
    font-size: 1.2rem;
  }
}