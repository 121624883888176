
.whyusContainer {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10%;
}

.headerSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(255, 255, 255, 0)), url('./15.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: white;
}

.headerSection h6 {
  font-size: 2rem;
  font-weight: 300;
}

.headerSection h2 {
  font-size: 4rem;
  line-height: 1em;
  font-weight: 500;
  margin-bottom: 3%;
}

.headerSection p {
  text-align: center;
  line-height: 1em;
}

.whyusContent {
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.whyuscard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 400px;
  width: 250px;
  border-radius: 0px;
  padding: 3em;
  color: black;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 5%;
  box-shadow: 0 15px 60px rgba(0, 0, 0, 0.3);
  margin-bottom: 10%;
  transition: all 0.5s ease;
  cursor: pointer;
  margin: 1%;
}

.whyuscard .whyusIcon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.whyuscard p {
  font-size: 1em;
}

.whyuscard:hover {
  transform: scale(1.1, 1.1);
}

.whyusContent:hover > .whyuscard:not(:hover) {
  filter: blur(10px);
  transform: scale(0.9, 0.9);
}



/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .whyuscard {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .whyuscard {
    width: 100%;
    margin-bottom: 5%;
    height: 40%;
  }

  .headerSection h6 {
    font-size: 1.2rem;
    font-weight: 300;
  }
  
  .headerSection h2 {
    font-size: 2rem;
    line-height: 1em;
    font-weight: 500;
  }
  
  .headerSection p {
    text-align: center;
    line-height: 1em;
  }
}
