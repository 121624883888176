.rebionContainer{
  background-color: black;
  width: 100%;
  color: white;
}

.ribbionContent{
  padding-top: 5em;
  padding-bottom: 5em;
}
.call{
  font-size: 1.5rem;
}
.ribbionButton {
  font-size: 1.3rem;
  padding: 1em;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: 3px solid var(--primary-color);
  border-color: var(--primary-color);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.ribbionButton:hover, .ribbionButton:active, .ribbionButton:visited {
  background-color:var(--background-color) !important;;
  border-color: var(--background-color) !important;;
  color: var(--primary-color);
  transform: scale(1.01); 
  box-shadow: 0 0 5px rgba(0,0,0,0.25);
}


@media (max-width: 480px) {
  .rebionContainer{
    justify-content: center;
    align-items: center;
  }

  .call{
    font-size: 1.2rem;
  }
  .ribbionButton {
    font-size: 1.2rem;
    padding: 0.6em;
  }
}