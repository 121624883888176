.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 10%;
  box-sizing: border-box;
  background-color: black;
  color: #fff;
  flex-direction: column;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap;
}

.footer-text {
  width: 100%;
  margin-bottom: 20px;
  text-align: center; 
}

.footer-text h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.contact-row {
  display: flex;
  justify-content: center;
}

.contact-column {
  display: flex;
  flex-direction: column;
  width: 48%;
  align-items: center;
}

.contact-item {
  display: flex;

  margin-bottom: 10px;
}

.contact-icon {
  margin-right: 10px;
  color: white;
}

.footer-text p {
  font-size: 1rem;
  margin-bottom: 5px;
  color: #ccc;
}

.footer-image {
  width: 40%;
  position: relative;
  margin-bottom: 20px;
}

.footer-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer-social {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.footer-social a {
  color: #fff;
  font-size: 24px;
  margin: 0 10px;
  transition: color 0.3s;
}

.footer-social a:hover {
  color: var(--primary-color);
}

.footer-copyright {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  border-top: 1px solid #444;
}

@media (max-width: 768px) {
  .footer-text, .footer-image {
    width: 100%;
    text-align: center;
  }

  .footer-social {
    margin-top: 20px;
  }

  .contact-row {
    flex-direction: column;
  }

  .contact-column {
    width: 100%;
  }
}
